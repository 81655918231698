.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

h2 {
  margin-bottom: 0;
}

h2 + p {
  margin-top: 5px;
  font-size: 14px;
}

.icons {
  display: flex;
  grid-gap: 10px;
}

.icon {
  color: #b9c0ce;
  transition: opacity 0.3s;
  opacity: 1;
}

.icon:hover {
  opacity: 0.75;
}
